import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "instructor" }
const _hoisted_2 = { class: "instructor__container" }
const _hoisted_3 = { class: "instructor__wrap" }
const _hoisted_4 = { class: "instructor__mobile-container" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "instructor__value" }
const _hoisted_7 = { class: "instructor__title" }
const _hoisted_8 = { class: "instructor__value" }
const _hoisted_9 = { class: "instructor__value" }
const _hoisted_10 = { class: "instructor__controls" }
const _hoisted_11 = { class: "instructor__popup-buttons" }
const _hoisted_12 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowBack = _resolveComponent("ArrowBack")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PhotoMock = _resolveComponent("PhotoMock")!
  const _component_Edit = _resolveComponent("Edit")!
  const _component_Delete = _resolveComponent("Delete")!
  const _component_AlertBlock = _resolveComponent("AlertBlock")!
  const _component_DefaultPopup = _resolveComponent("DefaultPopup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: "/super-admin/school-list",
      class: "instructor__back"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ArrowBack)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            (this.schoolData?.logo === null || this.schoolData?.logo === '')
              ? (_openBlock(), _createBlock(_component_PhotoMock, {
                  key: 0,
                  class: "instructor__photo"
                }))
              : _createCommentVNode("", true),
            (this.schoolData?.logo !== null && this.schoolData?.logo !== '')
              ? (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  src: this.schoolData?.logo,
                  class: "instructor__photo",
                  alt: ""
                }, null, 8, _hoisted_5))
              : _createCommentVNode("", true)
          ]),
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "instructor__title" }, " School name ", -1)),
          _createElementVNode("div", _hoisted_6, _toDisplayString(this.schoolData?.name), 1),
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "instructor__title" }, " School admin ", -1)),
          _createElementVNode("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.schoolData?.admins, (admin) => {
              return (_openBlock(), _createElementBlock("div", {
                key: admin.id,
                class: "instructor__value-name"
              }, _toDisplayString(admin.full_name), 1))
            }), 128))
          ]),
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "instructor__title" }, " School code ", -1)),
          _createElementVNode("div", _hoisted_8, _toDisplayString(this.schoolData?.code), 1),
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "instructor__title" }, " Free trial duration ", -1)),
          _createElementVNode("div", _hoisted_9, _toDisplayString(this.schoolData?.free_trial_days_interval), 1),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_Edit, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.editSchool(this.schoolData.id)))
            }),
            _createVNode(_component_Delete, {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openDeletePoup(this.schoolData.id)))
            })
          ])
        ])
      ])
    ]),
    (_ctx.isModalDelete)
      ? (_openBlock(), _createBlock(_component_DefaultPopup, {
          key: 0,
          "on-close": _ctx.handleCloseModalDelete
        }, {
          default: _withCtx(() => [
            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "instructor__popup-title" }, " Are you sure? ", -1)),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", {
                class: "instructor__popup-button-yes",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.deleteSchool && _ctx.deleteSchool(...args)))
              }, " Yes "),
              _createElementVNode("div", {
                class: "instructor__popup-button-no",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleCloseModalDelete && _ctx.handleCloseModalDelete(...args)))
              }, " No "),
              (this.errorInstructors)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createVNode(_component_AlertBlock, {
                      message: this.errorInstructors
                    }, null, 8, ["message"])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["on-close"]))
      : _createCommentVNode("", true)
  ]))
}